<template>

  <v-carousel
    cycle
    interval="10000"
    height="auto"
    hide-delimiters
    :show-arrows="false"
    v-if="adverts.length"
  >

    <v-carousel-item
      v-for="(ad,i) in adverts"
      :key="i"
    >
      <v-sheet
        height="100%"
        color="white"
        tile
      >
        <a class="wrapper" target="_blank" :href="ad.product_url">
          <div class="advert" v-html="ad.html"></div>
        </a>
        
      </v-sheet>


    </v-carousel-item>

  </v-carousel>

</template>

<script>
  import { fetchAdverts } from '@/services/http/adverts'
  export default {
    name: 'Adverts',
    data() {
      return {
        adverts: [],
        error: null
      }
    },
    async created() {
      try {
        this.adverts = await fetchAdverts()
      } catch(e) {
        this.error = e
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    display: block;
    text-decoration: none;
    color: #555;
  }
</style>
