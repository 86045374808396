<template>
  <v-dialog scrollable v-model="dialog" max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="btn-no-active"
        title="Date Filter"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-filter-menu-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Filter Chart Data</v-card-title>
      <v-divider></v-divider>

      <v-container fluid>
        <v-row no-gutters>
          <v-col cols="12">
            <v-select
              :items="filterLists"
              filled
              label="Choose Filter Type"
              item-value="_id"
              v-on:change="filterChange"
              item-text="name"
              v-model="defaultSelected"
              required
            >
            </v-select>
          </v-col>
          <v-col v-if="custom_date" cols="12">
            <v-card-text>
              <div>
                <v-text-field
                  ref="from_date_field"
                  label="From Date:"
                  type="date"
                  hide-details="auto"
                  v-model="from_date"
                >
                </v-text-field>
              </div>
            </v-card-text>
            <v-card-text>
              <div>
                <v-text-field
                  label="To Date:"
                  type="date"
                  hide-details="auto"
                  v-model="to_date"
                >
                </v-text-field>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" @click="closeDialog">
          Done
        </v-btn>
        <v-btn @click="cancelDialog">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import moment from "moment";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import { getFilterDates } from "@/services/default-date-filter";

export default {
  data: () => ({
    tz: "",
    date_format: "YYYY-MM-DD",
    dialog: false,
    from_date: null,
    to_date: null,
    filterLists: [],
    custom_date: false,
    filter: "custom_date",
    defaultSelected: { _id: 1, name: "Custom Date" }
  }),
  methods: {
    setFilters() {
      this.filterLists = [
        { _id: 1, name: "Custom Date" },
        { _id: 2, name: "Today" },
        { _id: 3, name: "Yesterday" },
        { _id: 4, name: "This Week" },
        { _id: 5, name: "This Month" },
        { _id: 6, name: "Since Last Month" },
        { _id: 7, name: "Last 2 Months" },
        { _id: 8, name: "Last 3 Months" },
        { _id: 9, name: "Last 4 Months" },
        { _id: 10, name: "Last 5 Months" },
        { _id: 11, name: "Last 6 Months" },
        {
          _id: 12,
          name: "This Year"
        }
      ];
    },
    closeDialog() {
      this.dialog = 0;
      this.updateValue();
    },
    cancelDialog() {
      this.dialog = 0;
    },
    filterChange(c) {
      if (c === 1) {
        this.filter = "custom_date";
        this.custom_date = true;
      } else {
        this.custom_date = false;
        if (c === 2) {
          this.filter = "today";
          this.today();
        } else if (c === 3) {
          this.filter = "yesterday";
          this.yesterday();
        } else if (c === 4) {
          this.filter = "this_week";
          this.thisWeek();
        } else if (c === 5) {
          this.filter = "this_month";
          this.thisMonth();
        } else if (c === 6) {
          this.filter = "last_month";
          this.lastMonths(1);
        } else if (c === 7) {
          this.filter = "last_2_month";
          this.lastMonths(2);
        } else if (c === 8) {
          this.filter = "last_3_month";
          this.lastMonths(3);
        } else if (c === 9) {
          this.filter = "last_4_month";
          this.lastMonths(4);
        } else if (c === 10) {
          this.filter = "last_5_month";
          this.lastMonths(5);
        } else if (c === 11) {
          this.filter = "last_6_month";
          this.lastMonths(6);
        } else if (c === 12) {
          this.filter = "this_year";
          this.thisYear();
        }
      }
    },
    today() {
      this.from_date = moment().startOf("day");
      this.to_date = moment().endOf("day");
    },
    yesterday() {
      this.from_date = moment()
        .subtract(1, "day")
        .startOf("day");
      this.to_date = moment()
        .subtract(1, "day")
        .endOf("day");
    },
    thisWeek() {
      this.from_date = moment().startOf("isoWeek");
      this.to_date = moment().endOf("day");
    },
    thisMonth() {
      this.from_date = moment().startOf("month");
      this.to_date = moment().endOf("day");
    },
    lastMonths(m) {
      this.from_date = moment()
        .subtract(m, "months")
        .startOf("month")
        .startOf("day");
      this.to_date = moment().endOf("day");
    },
    thisYear() {
      this.from_date = moment().startOf("year");
      this.to_date = moment().endOf("year");
    },

    async defaultDate() {
      const { from_date, to_date } = await getFilterDates();
      this.custom_date = true;
      this.from_date = moment(from_date).format(this.date_format);
      this.to_date = moment(to_date).format(this.date_format);
    },

    updateValue() {
      let tz = this.userTimezone();
      console.log("using timezone:", tz);
      this.clearMachineStats();
      this.syncMachineStats({
        from_date: moment(this.from_date)
          .tz(tz)
          .startOf("day")
          .format(),
        to_date: moment(this.to_date)
          .tz(tz)
          .endOf("day")
          .format(),
        filter: this.filter
      });
    },
    ...mapActions(["clearMachineStats", "syncMachineStats", "fetchUser"]),
    ...mapGetters(["userTimezone"])
  },
  async mounted() {
    this.tz = this.userTimezone();
    if (!this.tz) {
      await this.fetchUser();
      this.tz = this.userTimezone();
    }
    this.setFilters();
    await this.defaultDate();
    this.updateValue();
  }
};
</script>
