<template>
  <v-dialog
    scrollable
    persistent
    v-model="dialog"
    max-width="500px"
  >
    <v-card>
      <v-card-title>Activate Device</v-card-title>
      <v-divider></v-divider>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" v-if="is_activated">
              <v-alert type="warning">Device is already activated</v-alert>
            </v-col>
            <v-col cols="12" v-if="!device_id">
              <v-alert type="error">Invalid Payload!</v-alert>
            </v-col>
          </v-row>
          <br>
          <v-form ref="form" lazy-validation v-model="isFormValid">
            <v-text-field
              label="Device ID:"
              readonly
              v-model="device_id"
              :rules="cid_rules"
              :disabled="!form_enabled"
            ></v-text-field>

            <v-text-field
              label="Version:"
              readonly
              :disabled="!form_enabled"
              v-model="version"
            ></v-text-field>

            <v-text-field
              label="Device Alias (Optional):"
              v-model="device_alias"
              :disabled="!form_enabled"
            ></v-text-field>
            <v-select
              :items="available_product_keys"
              label="License Key *:"
              v-model="pk_code"
              :rules="pk_rules"
              required
              :disabled="!form_enabled"
            ></v-select>
          </v-form>

          <v-row>
            <v-col cols="12" v-if="success">
              <v-alert type="success">
                Machine successfully activated.
                <br>
                <strong>Please restart the machine for the activation status to take effect.</strong>
              </v-alert>
              <p>Redirecting to Machines page in 10 seconds</p>
            </v-col>

            <v-col cols="12" v-if="error">
              <v-alert type="error">{{error}}</v-alert>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="primary" :disabled="!form_enabled || !isFormValid || submitting || success" @click="activate" elevation="2" x-large>
          <span>{{submitting ? 'Activating ...' : 'Activate'}}</span>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="blue darken-1"
          text
          @click="cancel"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { getDeviceInfo, activate } from '@/services/http/activation'

  export default {
    name: 'ActivateDevice',
    components: {},
    props: {
     payload: null
   },
    data: () => ({
      submitting: false,
      isFormValid: true,
      is_activated: false,
      success: false,
      error: '',
      device_id: null,
      version: '',
      loading: false,
      pk_code: null,
      dialog: true,
      form_enabled: false,
      device_alias: '',
      available_product_keys: [],
      pk_rules: [v => !!v || 'License key is required'],
      cid_rules: [v => !!v || 'Device ID is required'],

    }),

    created() {
      this.load()
    },
    methods: {
      async load() {
        getDeviceInfo(this.payload).then((res) => {
          var resp = res.data || {}
          this.is_activated = resp.is_activated
          this.device_id = resp.cid
          this.version = resp.version
          this.available_product_keys = resp.available_product_keys
          this.form_enabled = !this.is_activated && this.device_id
        }).catch((e) => {
          console.log(e)
        })
      },
      activate () {
        this.isFormValid = this.$refs.form.validate()
        if (!this.isFormValid) return
        this.submitting = true
        activate({pk_code: this.pk_code, alias: this.device_alias, payload: this.payload}).then((res) => {
          var resp = res.data || {}
          this.success = !!resp.success
          if (this.success) {
            setTimeout(() => {
              window.location.href = "/#/Machines"
            }, 10000)
          }
          console.log(resp)
        }).catch((e) => {
          this.error = e
        }).finally(()=> {
          this.submitting = false
        })
      },
      cancel () {
        this.dialog = false
        window.location.href = "/#/"
      }
    }
  }
</script>