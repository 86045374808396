<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: reactiveProp,
  props: ['chartdata', 'options'],
  mounted () {
    this.renderLineChart()
  },
  methods: {
    renderLineChart() {
      this.renderChart(this.chartdata, this.options)
    }
  },
  watch: {
    data: () => {
      this._chart.destroy();
      this.renderLineChart();
    }
  }
}
</script>
