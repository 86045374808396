<template>
  <line-chart v-if="chartData" :chartdata="chartData" :options="options"></line-chart>
</template>

<script>
  import { mapGetters } from 'vuex'
  import LineChart from '@/components/charts/LineChart'

  export default {
    name: 'ActiveWifiUsersChart',
    components: { LineChart },
    data() {
      return {
        error: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      }
    },
    computed: {
      chartData() {
        return this.activeWifiUserStats
      },
      ...mapGetters(['activeWifiUserStats'])
    }
  }
</script>
