import http from '@/services/http/http';
import catch_http_error from '@/services/http/catch_http_error'
import validation_errors from '@/services/http/validation_errors'

export async function getDeviceInfo(payload) {
  try {
    const res = await http.post('/api/v1/activations', { payload });
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}

export async function activate(opts) {
  try {
    const res = await http.post('/api/v1/activations/activate', opts);
    return res
  } catch(e) {
    e.response = e.response || {}
    if (e.response.status === 422) {
      if (e.response.data.message)
        catch_http_error(e)
      else
        return Promise.reject(validation_errors(e))
    } else {
      catch_http_error(e)
    }
  }
}
