<template>
  <line-chart v-if="chartData" :chartdata="chartData" :options="options"></line-chart>
</template>

<script>
  import { mapGetters,mapActions } from 'vuex'
  import LineChart from '@/components/charts/LineChart'

  export default {
    name: 'SalesChart',
    components: { LineChart },
    data() {
      return {
        error: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          onClick: () => {
            this.clearHiddenSales();
            const {datasets} = this.saleStats;

            //make delay to process the boolean value of hidden
            setTimeout(() => {
              for(var j = 0; j < datasets.length; j++){
                const is_hidden = datasets[j]._meta[Object.keys(datasets[j]._meta)[0]].hidden;
                if(is_hidden)
                  this.setHiddenSales({sales: datasets[j].data, currency: datasets[j].currency, hidden: true})
                else
                  this.setHiddenSales({sales: [0], currency: datasets[j].currency, hidden: false})
              }
            },5)
          }
        }
      }
    },
    computed: {
      chartData() {
        return this.saleStats
      },
      ...mapGetters(['saleStats'])
    },
    methods: {
      ...mapActions(['fetchMachineStats','setHiddenSales','clearHiddenSales'])
    }
  }
</script>
