<template>
  <dashboard-layout>
    <template slot="heading">
      <v-toolbar-title class="d-none d-md-block">
        Dashboard
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <sales-filter></sales-filter>
    </template>


    <template slot="content">
      <v-container v-if="activation_payload">
        <activate-device :payload="activation_payload"></activate-device>
      </v-container>

      <loading-circular v-if="loading"></loading-circular>

      <v-container v-if="!loading">
        <div class="mb-5">
          <adverts></adverts>
        </div>
        <div class="block mb-5" v-if="!is_stats_subsc_active">
          <v-alert type="info">
            Note: Your sales logs are currently limited to 1 week data.
            <br>
            <a class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--small primary" href="#/settings?tab=account_bills">Subscribe</a> to view your sales report for up to 1 year.
          </v-alert>
        </div>
        <!-- Sales Summary -->
        <v-card class="mb-5" v-if="sales.length">
          <v-card-title>
            {{ salesLabel }}
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-subtitle>
            This is an estimated value base on the last sync interval per day. Or in order to get the real-time sales, connect your machine/s to NGROK.
          </v-card-subtitle>
          <v-card-text>
            <template v-for="sale in sales">
              <v-list-item :key="sale.currency">
                <v-chip>
                  {{ sale.currency }} {{ sale.sales || '0' }}
                </v-chip>
              </v-list-item>
            </template>
          </v-card-text>
        </v-card>
        <!-- End Sales Summary -->
        <div>
          <v-card class="mb-5">
            <v-card-title>Sales Report</v-card-title>
            <v-card-text>
              <div class="sales-chart" v-if="saleStats">
                <sales-chart :height="200"></sales-chart>
              </div>
              <v-alert type="warning" v-if="!saleStats">
                No data to show
              </v-alert>
            </v-card-text>
          </v-card>
  
          <v-card class="mb-5">
            <v-card-title>Active Wifi Users</v-card-title>
            <v-card-text>
              <div class="active-wifi-users-chart" v-if="activeWifiUserStats">
                <active-wifi-users-chart :height="200"></active-wifi-users-chart>
              </div>
              <v-alert type="warning" v-if="!activeWifiUserStats">
                No data to show
              </v-alert>
            </v-card-text>
          </v-card>
  
          <v-card class="mb-5">
            <v-card-title>Total Wifi Users</v-card-title>
            <v-card-text>
              <div class="total-wifi-users-chart" v-if="totalWifiUserStats">
                <total-wifi-users-chart :height="200"></total-wifi-users-chart>
              </div>
              <v-alert type="warning" v-if="!totalWifiUserStats">
                No data to show
              </v-alert>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </template>
  </dashboard-layout>
</template>

<script>
import Adverts from '@/components/Adverts.vue'
import { mapGetters, mapActions } from 'vuex'
import SalesChart from '@/views/dashboard/dashboard/SalesChart.vue'
import ActiveWifiUsersChart from '@/views/dashboard/dashboard/ActiveWifiUsersChart.vue'
import TotalWifiUsersChart from '@/views/dashboard/dashboard/TotalWifiUsersChart.vue'
import SalesFilter from '@/views/dashboard/home/SalesFilter.vue'
import LoadingCircular from '@/components/LoadingCircular.vue'
import ActivateDevice from '@/components/ActivateDevice.vue'
import { get_sales_report_payment } from '@/services/http/machines.js'

export default {
  name: 'DashboardHome',
  data: () => ({
    is_stats_subsc_active: false,
    activation_payload: null
  }),
  components: {
    SalesChart,
    Adverts,
    ActiveWifiUsersChart,
    TotalWifiUsersChart,
    SalesFilter,
    LoadingCircular,
    ActivateDevice,
},
  mounted() {
    this.clearMachineStats()
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    var _this = this
    get_sales_report_payment().then(function(res) {
      if(res && res.sales_report_payment) {
        _this.is_stats_subsc_active = res.sales_report_payment && !res.sales_report_payment.subscription_message
      }
    })

    if (window.location.pathname.match(/activate/i) && params.payload) {
      this.activation_payload = params.payload
    }
  },
  methods: {
    ...mapActions(['clearMachineStats'])
  },
  computed: {
    ...mapGetters([
      'loading',
      'salesLabel',
      'sales',
      'saleStats',
      'activeWifiUserStats',
      'totalWifiUserStats'  
    ])
  }
}
</script>

<style lang="scss">
</style>
